import { createSlice } from '@reduxjs/toolkit';

const tourSlice = createSlice({
  name: 'tour',
  initialState: {
    run: false,
  },
  reducers: {
    setTourStatus: (state, action) => {
      state.run = action.payload;
    },
  },
});

export const { setTourStatus } = tourSlice.actions;

export default tourSlice.reducer;

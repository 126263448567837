import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/leadSlice";
import tourSlice from "../features/common/tourSlice";
import mapSlice from "../features/common/components/MapSelector/MapSlice";
import reservationsSlice from "../features/reservations/reservationsSlice";
import userSlice from "../features/user/stor/userSlice";
const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  tour: tourSlice,
  map: mapSlice,
  reservations: reservationsSlice,
  user: userSlice,
};

export default configureStore({
  reducer: combinedReducer,
});

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedPosition: null,
    locationName: "Choose your location",
  };
  
  const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
      setSelectedPosition(state, action) {
        state.selectedPosition = action.payload.location;
        state.locationName = action.payload.locationName;
      },
      // Add other reducers here if necessary
    },
  });
  
  export const { setSelectedPosition } = mapSlice.actions;
  export default mapSlice.reducer;
  

// /src/features/reservations/reservationsSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getReservations,
  reserveApi,
  approveReservationApi,
  rejectReservationApi,
} from "./api"; // Import the new API function

// Async thunk for fetching reservations
export const fetchReservations = createAsyncThunk(
  "reservations/fetchReservations",
  async ({ status, page_size, page_number, userId }, thunkAPI) => {
    try {
      const response = await getReservations({
        status,
        page_size,
        page_number,
        userId,
      });
      return response.data; // Assuming response.data contains the reservations
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error response
    }
  }
);
export const approveReservation = createAsyncThunk(
  "reservations/approveReservation",
  async ({ reservation_id }, thunkAPI) => {
    try {
      const response = await approveReservationApi({ reservation_id });
      return { reservation_id, data: response }; // Assuming response contains the updated reservation info
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error response
    }
  }
);
export const rejectReservation = createAsyncThunk(
  "reservations/rejectReservation",
  async ({ reservation_id }, thunkAPI) => {
    try {
      const response = await rejectReservationApi({ reservation_id });
      return { reservation_id, data: response }; // Assuming response contains the updated reservation info
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error response
    }
  }
);

export const reserve = createAsyncThunk(
  "reservations/reserve",
  async ({ empty_leg, payment_type }, thunkAPI) => {
    try {
      const response = await reserveApi({ empty_leg, payment_type });
      return response.data; // Assuming the response returns data that needs to be added to the list
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error response
    }
  }
);

export const reservationsSlice = createSlice({
  name: "reservations",
  initialState: {
    isLoading: false,
    list: [],
    reservations: [],
    total: 0,
    page_number: 1,
    error: null, // Add error state to handle errors
  },
  reducers: {
    addReservations: (state, action) => {
      let { newList } = action.payload;
      state.list = [...state.list, ...newList];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservations.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(fetchReservations.fulfilled, (state, action) => {
        state.isLoading = false;
        const newPageNumber = action.payload?.page_number || 1;
        const newReservations = action.payload?.data || [];
      
        if (newPageNumber > 1) {
          // Append new data to the existing reservations if page_number > 1
          state.reservations = [...state.reservations, ...newReservations];
        } else {
          // Replace the list if it's the first page
          state.reservations = newReservations;
        }
      
        // Update other state properties
        if (action.payload?.total) {
          state.total = action.payload.total;
        }
        state.page_number = newPageNumber;
      })
      
      .addCase(fetchReservations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Set error message
      })
      .addCase(reserve.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(reserve.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = [...state.list, action.payload]; // Assuming action.payload is the new reservation object
      })
      .addCase(reserve.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Set error message
      })
      .addCase(approveReservation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(approveReservation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(rejectReservation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(rejectReservation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { addReservations } = reservationsSlice.actions;

export default reservationsSlice.reducer;

export const selectReservations = (state) => state.reservations.reservations;
export const selectIsLoading = (state) => state.reservations.isLoading;
export const selectError = (state) => state.reservations.error;
export const selectTotalPage = (state) => state.reservations.total;

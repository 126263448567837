const APIConfig = {
  baseURL: process.env.REACT_APP_BASE_URL, // Use the environment variable
  timeout: 30000, // in ms
  validateStatus: (status) => status >= 200 && status <= 400,
  headers: {
    "Content-Type": "application/json", // Set the content type
  },
  endpoints: [
    {
      key: "login",
      url: "/login",
      method: "POST",
    },
    {
      key:'flights',
      url:'/flights',
      method: "GET",
    },
    {
      key:'cities',
      url:'/cities',
      method: "GET",
    },
    {
      key:'airports',
      url:'/airports',
      method: "GET",
    },
    {
      key:'userList',
      url:'/users/users',
      method: "GET",
    }

  ],
};

export default APIConfig;

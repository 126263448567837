// src/features/users/stor/userSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { changeUserRoleApi } from './api'; // Adjust path to your api file

// Define the async thunk for changing user roles
export const changeUserRole = createAsyncThunk(
  'userRole/changeUserRole',
  async ({ userId, newRole }, { rejectWithValue }) => {
    try {
      const response = await changeUserRoleApi({ userId, newRole });
      return response;
    } catch (error) {
      // Return a rejection value if the API call fails
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeUserRole.pending, (state) => {
        state.isLoading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = action.payload;
      })
      .addCase(changeUserRole.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;

import img from '../components/Images/plane01.png';

function SuspenseContent() {
    return (
        <div className="w-full h-screen flex flex-col  items-center justify-center
         text-center text-brand-disabled-900x bg-brand-disabled-900 text-brand-300 font-bold ">
            <img src={img} width="100px" alt="Loading icon"  className='mb-4 animate-opacityLoop'/>
            Loading...
        </div>
    );
}

export default SuspenseContent;

import axios from 'axios';
import APIConfig from './APIConfig';

const axiosClient = axios.create({
  baseURL: APIConfig.baseURL,
  timeout: APIConfig.timeout,
  headers: APIConfig.headers,
  validateStatus: APIConfig.validateStatus,
});

axiosClient.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      // Redirect to login page
      if (window.location.pathname !== '/login') {
        // Redirect the user to the login page
       // window.location.href = '/';
       // localStorage.clear();
        return Promise.reject(error?.response || 'Something  wrong!');
      }

      return Promise.reject(error?.response || 'Something  wrong!');
    }
    return Promise.reject(error?.response || 'Something  wrong!');
  }
);

export const setAuthToken = (token) => {
  axiosClient.defaults.headers.Authorization = `Bearer ${token}`;
};

export const setRefreshToken = (refreshToken, username, onResponse) => {
  axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status !== 401) {
        return Promise.reject(error);
      }
      const data = await login({
        username,
        refreshToken,
        grantType: 'refresh_token',
      });
      onResponse({
        token: data.access_token,
        refreshToken: data.refresh_token,
      });
      originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
      return axiosClient(originalRequest);
    }
  );
};

export default axiosClient;

async function login() {
  // Your login function implementation
}

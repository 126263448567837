import axiosClient from "./../../utils/axiosClient"; // Make sure to import your axiosClient

export async function reserveApi({ empty_leg, payment_type }) {
  try {
    const response = await axiosClient.post(
      `/reserve?empty_leg=${empty_leg}&payment_type=${payment_type}`
    );
    return response; // Assuming a successful response returns relevant data
  } catch (error) {
    console.log("--->", error);
    throw error; // Handle or rethrow the error
  }
}

export async function getReservations({
  status = null,
  userId = null,
  page_size = 20,
  page_number = 1,
}) {
  try {
    // Construct the query parameters dynamically
    const params = new URLSearchParams();

    if (status) {
      params.append("status", status);
    }

    if (userId && Number(userId)) {
      params.append("user_id_filter", Number(userId));
    }

    // Page size and page number are always included
    params.append("page_size", page_size);
    params.append("page_number", page_number);

    // Make the API request with the constructed query string
    const response = await axiosClient.get(
      `/reservations?${params.toString()}`
    );
    return response; // Assuming a successful response returns relevant data
  } catch (error) {
    console.error("Error fetching reservations:", error);
    throw error; // Handle or rethrow the error
  }
}

export async function approveReservationApi({ reservation_id }) {
  try {
    const response = await axiosClient.put(
      `/approve?reservation_id=${reservation_id}`
    );
    return response.data; // Assuming a successful response returns relevant data
  } catch (error) {
    console.error("---> Error approving reservation:", error);
    throw error; // Re-throw the error to be caught by the thunk
  }
}

export const rejectReservationApi = async ({ reservation_id }) => {
  try {
    const response = await axiosClient.put(
      `/cancel?reservation_id=${reservation_id}`
    );
   
    return response?.data; // Assuming response.data contains the relevant data
  } catch (error) {
    throw error; // Handle or rethrow the error as necessary
  }
};
